import React from 'react'
import * as ROUTES from '../../constants/routes'
import { Card, CardHeader, CardBody, Col, Container, Row } from 'reactstrap'

class Terms extends React.Component {
  render() {
    return (
      <Container fluid className="p-0">
        <Row className="mt-3">
          <Col md="2" />
          <Col md="8">
            <div id={`step-1`}>
              <Card>
                <CardHeader>
                  <Row className="mt-3">
                    <Col style={{ textAlign: 'center' }}>
                      <h2 className="">Terms & Conditions</h2>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="mt-4">
                    <h3>1. No Attorney-Client Relationship or Legal Advice</h3>
                    <big>
                      The contents of this website, including the free will tool
                      (1) are not provided in the course of and do not create or
                      constitute an attorney-client relationship, (2) are not
                      intended as a solicitation, (3) are not intended to convey
                      or constitute legal advice, and (4) do not a substitute
                      for obtaining legal advice from a qualified attorney.
                    </big>
                  </div>
                  <div className="mt-4">
                    <h3>2. Privacy Statement</h3>
                    <big>
                      If you choose to provide us with personal information
                      about yourself, either through the free will tool on our
                      Web site or by sending us e-mail, we may collect and use
                      that information to contact you or to send you additional
                      information. We do not sell any personally identifiable
                      information provided by our visitors to any unrelated
                      third party.
                    </big>
                    <br />
                    <big>
                      Although we use security that we believe to be appropriate
                      to protect the information disclosed to us on this Web
                      site, we do not guarantee that our security precautions
                      will protect against and we expressly disclaim any
                      liability for any loss, misuse, or alteration of your
                      information or from your access to or use of our
                      information.
                    </big>
                  </div>
                  <div className="mt-4">
                    <h3>3. No Warranty or Liability</h3>
                    <big>
                      The information and contents of this Web site are provided
                      "as is" without representation or warranty of any kind --
                      as to suitability, reliability, applicability,
                      merchantability, fitness, noninfringement, result, outcome
                      or any other matter. We do not represent or warrant that
                      such information is or will be always up-to-date,
                      complete, or accurate. Any representation or warranty that
                      might be otherwise implied is expressly disclaimed.
                    </big>
                    <big>
                      You agree that we are not liable to you or others, in any
                      way or for any damages of any kind or under any theory,
                      arising from this site, or your access to or use of or
                      reliance on the information or products created through
                      this site, including but not limited to liability or
                      damages under contract or tort theories or any damages
                      caused by viruses contained within electronic files of
                      this site or any linked site, regardless of prior notice
                      to us.
                    </big>
                  </div>
                  <div className="mt-4">
                    <h3>4. Intellectual Property Rights</h3>
                    <big>
                      All the contents of this website are protected under
                      copyright laws and are owned, controlled or licensed by
                      Muslim.Estate. You may download and utilize material
                      created on this Web site for free for your personal and
                      non-commercial use only. No part of these materials may
                      otherwise be sold for a profit without the prior written
                      permission from Muslim.Estate.
                    </big>
                  </div>
                  <hr></hr>
                  <Row>
                    <Col>
                      <button
                        className="btn-pill btn btn-submit btn-primary btn-lg"
                        type="button"
                        onClick={() => {
                          this.props.history.push(ROUTES.HOME)
                        }}
                      >
                        <b>Go Back</b>
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col md="2" />
        </Row>
      </Container>
    )
  }
}

export default Terms
