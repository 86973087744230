import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Footer = () => (
  <footer className="footer sticky-bottom">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-left">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                <a href="/contact">Contact</a>
              </span>
            </li>
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                <a href="/faqs">FAQ</a>
              </span>
            </li>
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                <a href="/terms">Terms of Service</a>
              </span>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-right">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{' '}
            <span href="/" className="text-muted">
              Muslim.Estate. All Rights Reserved
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
