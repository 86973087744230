import React from 'react'
import '../../assets/css/custom.css'
import { Spinner } from 'reactstrap'
import * as ROUTES from '../../constants/routes'

class Landing extends React.Component {
  constructor(props) {
    super(props)
    this.openQuiz()
  }

  openQuiz() {
    this.props.history.push(ROUTES.HOME)
  }

  render() {
    return <Spinner color="primary" />
  }
}

export default Landing
