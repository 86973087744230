export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const HOME = '/home'
export const QUIZ = '/quiz'
export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/pw-forget'
export const FAQ = '/faqs'
export const TERMS = '/terms'
export const CONTRIBUTE = '/contribute'
export const CONTACT = '/contact'
