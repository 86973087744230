import React from 'react'

import { withFirebase } from '../Firebase'
import { NavbarText } from 'reactstrap'

const SignOutLink = ({ firebase }) => (
  <NavbarText
    onClick={() => {
      console.log('Signout')
      firebase.doSignOut()
      window.location.href = 'https://muslim.estate'
    }}
  >
    Sign Out
  </NavbarText>
)

export default withFirebase(SignOutLink)
