import axios from 'axios'
import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import Swal from 'sweetalert2'

function Contact() {
  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const entries = Object.fromEntries(formData.entries())
    const payload = {
      name: entries.firstname + ' ' + entries.lastname,
      email: entries.email,
      location: entries.city + ', ' + entries.state,
      message: entries.message
    }

    const URL = process.env.REACT_APP_BACKEND_URL + 'contact-form-submit'
    axios
      .post(URL, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Thank you!',
          text: 'We will get back to you as soon as possible.'
        })
        e.target.reset()
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong. Please try again later.'
        })
        console.error(error)
      })
  }

  return (
    <Container fluid className="p-0" style={{ minHeight: '85vh' }}>
      <Row className="justify-content-center mt-3">
        <Col md="8" xs="12">
          <Card>
            <CardHeader>
              <Row className="mt-3">
                <Col style={{ textAlign: 'center' }}>
                  <h2 className="">Contact Us</h2>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className="justify-content-center mt-3">
                <Col md="10">
                  <big>
                    <p>
                      If you have any questions, fill out the form below and we
                      will get back to you as soon as possible.
                    </p>
                  </big>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="11" md="8">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="firstname">First Name</Label>
                          <Input
                            id="firstname"
                            name="firstname"
                            placeholder="Enter first name"
                            type="text"
                            size="lg"
                            maxLength={150}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="lastname">Last Name</Label>
                          <Input
                            id="lastname"
                            name="lastname"
                            placeholder="Enter last name"
                            type="text"
                            size="lg"
                            maxLength={150}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        type="email"
                        size="lg"
                        maxLength={150}
                        required
                      />
                    </FormGroup>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="city">City</Label>
                          <Input
                            id="city"
                            name="city"
                            placeholder="Enter city"
                            type="text"
                            size="lg"
                            maxLength={150}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="state">State</Label>
                          <Input
                            id="state"
                            name="state"
                            placeholder="Select State"
                            type="select"
                            size="lg"
                            maxLength={150}
                            required
                          >
                            <option value="">Select State</option>
                            <option value="AL">Alabama (AL)</option>
                            <option value="AK">Alaska (AK)</option>
                            <option value="AZ">Arizona (AZ)</option>
                            <option value="AR">Arkansas (AR)</option>
                            <option value="CA">California (CA)</option>
                            <option value="CO">Colorado (CO)</option>
                            <option value="CT">Connecticut (CT)</option>
                            <option value="DE">Delaware (DE)</option>
                            <option value="DC">
                              District Of Columbia (DC)
                            </option>
                            <option value="FL">Florida (FL)</option>
                            <option value="GA">Georgia (GA)</option>
                            <option value="HI">Hawaii (HI)</option>
                            <option value="ID">Idaho (ID)</option>
                            <option value="IL">Illinois (IL)</option>
                            <option value="IN">Indiana (IN)</option>
                            <option value="IA">Iowa (IA)</option>
                            <option value="KS">Kansas (KS)</option>
                            <option value="KY">Kentucky (KY)</option>
                            <option value="LA">Louisiana (LA)</option>
                            <option value="ME">Maine (ME)</option>
                            <option value="MD">Maryland (MD)</option>
                            <option value="MA">Massachusetts (MA)</option>
                            <option value="MI">Michigan (MI)</option>
                            <option value="MN">Minnesota (MN)</option>
                            <option value="MS">Mississippi (MS)</option>
                            <option value="MO">Missouri (MO)</option>
                            <option value="MT">Montana (MT)</option>
                            <option value="NE">Nebraska (NE)</option>
                            <option value="NV">Nevada (NV)</option>
                            <option value="NH">New Hampshire (NH)</option>
                            <option value="NJ">New Jersey (NJ)</option>
                            <option value="NM">New Mexico (NM)</option>
                            <option value="NY">New York (NY)</option>
                            <option value="NC">North Carolina (NC)</option>
                            <option value="ND">North Dakota (ND)</option>
                            <option value="OH">Ohio (OH)</option>
                            <option value="OK">Oklahoma (OK)</option>
                            <option value="OR">Oregon (OR)</option>
                            <option value="PA">Pennsylvania (PA)</option>
                            <option value="RI">Rhode Island (RI)</option>
                            <option value="SC">South Carolina (SC)</option>
                            <option value="SD">South Dakota (SD)</option>
                            <option value="TN">Tennessee (TN)</option>
                            <option value="TX">Texas (TX)</option>
                            <option value="UT">Utah (UT)</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="message">Message</Label>
                      <Input
                        id="message"
                        name="message"
                        placeholder="Please enter your message here and be as detailed as possible."
                        type="textarea"
                        size="lg"
                        maxLength={500}
                        minLength={20}
                        rows={6}
                        required
                      />
                    </FormGroup>
                    <Button type="submit" color="primary" size="lg">
                      Send
                    </Button>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Contact
