import React from 'react'
import Fade from 'react-reveal/Fade'
import * as ROUTES from '../../constants/routes'

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusSquare,
  faMinusSquare
} from '@fortawesome/free-regular-svg-icons'

class Faqs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      questionOne: false,
      questionTwo: false,
      questionThree: false,
      questionFour: false,
      questionFive: false,
      questionSix: false,
      questionSeven: false,
      questionEight: false,
      questionNine: false,
      questionTen: false,
      questionEleven: false
    }
    this.openQuestion = this.openQuestion.bind(this)
    this.openQuiz = this.openQuiz.bind(this)
  }

  openQuiz() {
    this.props.history.push(ROUTES.HOME)
  }

  openQuestion(id) {
    switch (id) {
      case 1:
        this.setState({
          questionOne: !this.state.questionOne
        })
        break
      case 2:
        this.setState({
          questionTwo: !this.state.questionTwo
        })
        break
      case 3:
        this.setState({
          questionThree: !this.state.questionThree
        })
        break
      case 4:
        this.setState({
          questionFour: !this.state.questionFour
        })
        break
      case 5:
        this.setState({
          questionFive: !this.state.questionFive
        })
        break
      case 6:
        this.setState({
          questionSix: !this.state.questionSix
        })
        break
      case 7:
        this.setState({
          questionSeven: !this.state.questionSeven
        })
        break
      case 8:
        this.setState({
          questionEight: !this.state.questionEight
        })
        break
      case 9:
        this.setState({
          questionNine: !this.state.questionNine
        })
        break
      case 10:
        this.setState({
          questionTen: !this.state.questionTen
        })
        break
      case 11:
        this.setState({
          questionEleven: !this.state.questionEleven
        })
        break
      default:
        console.error('ERROR OPENING FAQ DETAIL')
    }
  }

  render() {
    return (
      <Container fluid className="p-0">
        <Row className="mt-3">
          <Col md="2" />
          <Col md="8">
            <Card>
              <CardHeader>
                <Row className="mt-3">
                  <Col style={{ textAlign: 'center' }}>
                    <h2 className="">Frequently Asked Questions</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div>
                  <Row onClick={this.openQuestion.bind(this, 1)}>
                    <Col md="11">
                      <b className="text-lg">What is Estate Planning?</b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionOne ? faMinusSquare : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionOne ? 'block' : 'none',
                        transition: 'display 1s'
                      }}
                    >
                      <Label className="text-lg">
                        Estate Planning is the process of creating a plan to:
                      </Label>
                      <ul>
                        <li className="text-lg">
                          Control your assets while you are alive and well.
                        </li>
                        <li className="text-lg">
                          Care for yourself and your loved ones in the event of
                          incapacity
                        </li>
                        <li className="text-lg">
                          Give what you own to those who you want upon your
                          death in a tax-efficient, cost-effective, and
                          well-organized manner.
                        </li>
                      </ul>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 2)}>
                    <Col md="11">
                      <b className="text-lg">What if I don’t have an estate?</b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionTwo ? faMinusSquare : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionTwo ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        We hear that a lot. People think estate planning is
                        something only the rich or elderly should do. But in
                        reality, your “estate” is simply a fancy way of saying
                        “your stuff.” It’s everything you own subtracted by
                        everything you owe.
                      </Label>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 3)}>
                    <Col md="11">
                      <b className="text-lg"> What makes a plan Islamic?</b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionThree
                            ? faMinusSquare
                            : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionThree ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Muslims believe that wealth is a trust from Allah. Just
                        like we have obligations and restrictions on how we can
                        earn and spend our wealth, we also don’t have unlimited
                        discretion to dispose of it after our death. The Prophet
                        Muhammad SAW is reported to have said a believer should
                        not let two nights pass without preparing a written will
                        (or estate plan as we known it). Under the Islamic Law
                        of Inheritance—which is the default system of
                        inheritance for Muslims in most Muslim-majority
                        countries as well as in countries with historically
                        large Muslim minorities—a Muslim’s assets must be
                        distributed based upon certain rules laid out in the
                        Quran, the Muslim holy scripture, and the Hadith, the
                        normative traditions of the Prophet Muhammad.
                      </Label>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 4)}>
                    <Col md="11">
                      <b className="text-lg">
                        {' '}
                        Is it legal to make an Islamic Estate Plan in the U.S?
                      </b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionFour ? faMinusSquare : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionFour ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        In the United States, people are typically free to leave
                        whatever they want to whomever they want after they die,
                        with the exception of a surviving spouse, whose share is
                        protected under most state laws, so long as they prepare
                        a legally valid estate plan before their death. Thus,
                        absent a challenge by the surviving spouse, a court will
                        likely uphold a properly executed will and honor the
                        decedent’s wishes. But if you’re concerned, keep reading
                        for a better solution to this potential problem.
                      </Label>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 5)}>
                    <Col md="11">
                      <b className="text-lg"> What is a Will?</b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionFive ? faMinusSquare : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionFive ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Most people have heard of a will. Your will accomplishes
                        three primary objectives:
                      </Label>
                      <ul>
                        <li className="text-lg">
                          names individuals (or charitable organizations) who
                          will receive your assets after your death, either by
                          outright gift or in a trust;
                        </li>
                        <li className="text-lg">
                          nominates an executor who will be appointed and
                          supervised by the probate court to manage your estate;
                          pay your debts, expenses and taxes; and distribute
                          your estate according to the instructions in your
                          will;{' '}
                        </li>
                        <li className="text-lg">
                          include nominations of guardians for minor children.
                          If nothing else, the third benefit is enough reason
                          for every Muslim with children in the United States to
                          prepare a will.
                        </li>
                      </ul>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 6)}>
                    <Col md="11">
                      <b className="text-lg">
                        {' '}
                        Is this site really free? What’s the catch?
                      </b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionSix ? faMinusSquare : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionSix ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        There is no catch. We want to ensure that every American
                        Muslim should, at a minimum, have a basic legally valid
                        will in place to protect their family. That’s what our
                        free automated tool provides. If you need a more complex
                        solution, you can purchase time with one of our partner
                        attorneys.
                      </Label>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 7)}>
                    <Col md="11">
                      <b className="text-lg">
                        Are there any limitations to creating a will?
                      </b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionSeven
                            ? faMinusSquare
                            : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionSeven ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Yes, several in fact, which people often don’t realize.
                        First off, a will does not control the disposition of
                        any jointly owned assets (your home or joint bank
                        accounts, for instance) or assets that already have
                        beneficiary designations (life insurance policies or
                        retirement accounts). Those assets automatically pass,
                        as a matter of law, to the joint-owner or beneficiary.
                        While that may not seem concerning at first blush, it’s
                        important to note that it likely won’t be consistent
                        with the Islamic rules of Inheritance. Second, wills
                        have to go through the probate process which can be
                        expensive, time-consuming, and open to the public.
                      </Label>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 8)}>
                    <Col md="11">
                      <b className="text-lg">
                        Is there a better alternative to a will?
                      </b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionEight
                            ? faMinusSquare
                            : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionEight ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Yes, in most cases, a living trust is superior to a
                        will. A trust functions similarly to a will, but in most
                        cases, is far more effective. With a revocable living
                        trust, your assets are put into the trust, administered
                        for your benefit during your lifetime and transferred to
                        your beneficiaries when you die — all without the need
                        for court involvement. Thus a well-drafted and
                        fully-funded trust avoids the need for an unnecessary
                        probate proceeding and allows for efficient and private
                        estate administration after your passing. In addition, a
                        trust is a highly effective tool for incapacity
                        planning.
                      </Label>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 9)}>
                    <Col md="11">
                      <b className="text-lg">
                        What about advance healthcare directives?
                      </b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionNine ? faMinusSquare : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionNine ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        Islamic estate planning implicates not only a client’s
                        physical assets, but also her very body and life. Like
                        other religions, Islam provides guidelines with respect
                        to end-of-life planning, organ donations, funeral
                        instructions, and disposition of a decedent’s body,
                        which come into play when developing a Muslim client’s
                        advance health care directives. The failure to have an
                        advance directive in place may, unfortunately, result in
                        an emotional dispute between loved ones regarding your
                        treatment, or even worse, a prolonged and divisive court
                        battle.
                      </Label>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 10)}>
                    <Col md="11">
                      <b className="text-lg">
                        Does creating an automated will through this website
                        establish an attorney-client relationship?
                      </b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionTen ? faMinusSquare : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionTen ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        No, this basic will does not constitute legal advice and
                        does not establish an attorney-client relationship with
                        our law firm.
                      </Label>
                    </div>
                  </Fade>
                  <hr />
                </div>

                <div>
                  <Row onClick={this.openQuestion.bind(this, 11)}>
                    <Col md="11">
                      <b className="text-lg">
                        What if I’m looking for a more complex solution?
                      </b>
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        size="lg"
                        icon={
                          this.state.questionEleven
                            ? faMinusSquare
                            : faPlusSquare
                        }
                      />
                    </Col>
                  </Row>
                  <Fade>
                    <div
                      style={{
                        display: this.state.questionEleven ? 'block' : 'none'
                      }}
                    >
                      <Label className="text-lg">
                        If you need a customized solution our Islamic estate
                        planning attorneys can assist you. Contact us today.
                      </Label>
                    </div>
                  </Fade>
                  <hr />
                  <Row>
                    <Col>
                      <button
                        className="btn-pill btn btn-submit btn-primary btn-lg"
                        type="button"
                        onClick={this.openQuiz}
                      >
                        <b>Go Back</b>
                      </button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="2" />
        </Row>
      </Container>
    )
  }
}

export default Faqs
