import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthUserContext } from '../Session'
import SignOutLink from '../SignOut'
import * as ROUTES from '../../constants/routes'
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap'

const Navigation = () => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <div>
          <NavigationAuth authUser={authUser} />
        </div>
      ) : (
        <NavigationAuth />
      )
    }
  </AuthUserContext.Consumer>
)

const LinkStyle = {
  fontSize: '16px',
  marginLeft: '10px',
  textDecoration: 'underline',
  lineHeight: '30px'
}

const NavigationAuth = ({ authUser }) => {
  useEffect(() => {
    if (authUser && ['/signin', '/signup'].includes(window.location.pathname)) {
      window.location.href = ROUTES.HOME
    }
  }, [])

  return (
    <Navbar color="white" fixed="fixed" expand style={{ padding: '0' }}>
      <NavbarBrand href="https://muslim.estate">
        <img
          src="/logo.png"
          height="50"
          alt="muslim.estate"
          style={{ borderRadius: '10px' }}
          className="ml-1"
        />
      </NavbarBrand>
      {authUser ? (
        <>
          <UncontrolledDropdown nav inNavbar className="ml-auto vr-nav">
            <DropdownToggle style={LinkStyle} nav>
              <img
                height={35}
                width={35}
                src={`https://ui-avatars.com/api/?background=002E5D&color=fff&name=${authUser.name}&rounded=true&bold=true`}
                alt="Profile"
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <Link to={ROUTES.HOME}>Home</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to={ROUTES.CONTRIBUTE}>Contribute</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to={ROUTES.CONTACT}>Contact Us</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to={ROUTES.FAQ}>FAQs</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to={ROUTES.ACCOUNT}>Change Password</Link>
              </DropdownItem>
              <DropdownItem>
                <SignOutLink />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <div className="ml-auto hr-nav">
            <Collapse navbar>
              <Nav className="ml-auto mr-3" navbar>
                <NavItem>
                  <NavLink style={LinkStyle} href={ROUTES.HOME}>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={LinkStyle} href={ROUTES.CONTRIBUTE}>
                    Contribute
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={LinkStyle} href={ROUTES.FAQ}>
                    FAQs
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle style={LinkStyle} nav>
                    <img
                      height={35}
                      width={35}
                      src={`https://ui-avatars.com/api/?background=002E5D&color=fff&name=${authUser.name}&rounded=true&bold=true`}
                      alt="Profile"
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavbarText>Logged in with {authUser?.email}</NavbarText>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to={ROUTES.CONTACT}>Contact Us</Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to={ROUTES.ACCOUNT}>Change Password</Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <SignOutLink />
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </div>
        </>
      ) : (
        <Collapse navbar>
          <Nav className="ml-auto mr-3" navbar>
            {window.location.pathname.includes('signup') && (
              <NavItem>
                <Link style={LinkStyle} to={ROUTES.SIGN_IN}>
                  Sign In
                </Link>
              </NavItem>
            )}
            {window.location.pathname.includes('signin') && (
              <NavItem>
                <Link style={LinkStyle} to={ROUTES.SIGN_UP}>
                  Sign Up
                </Link>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      )}
    </Navbar>
  )
}

const NavigationNonAuth = () => <ul></ul>

export default Navigation
