import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { compose } from 'recompose'
import { AuthUserContext, withAuthorization } from '../Session'
import { Spinner } from 'reactstrap'
import './style.css'
const USER = process.env.REACT_APP_BACKEND_URL + 'v2/user'

const Home = (props) => {
  const [name, setName] = useState(props.authUser?.displayName)

  useEffect(() => {
    const authUser = props.authUser
    const uid = authUser.uid
    // If the name is not set, then we will fetch it from the backend
    if (!name) {
      axios
        .get(`${USER}/${uid}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authUser.token
          }
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setName(response.data.data.name)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [])

  const PaidIcon = () => (
    <img
      title="Paid"
      alt="Paid"
      height={18}
      src="/crown.png"
      style={{
        float: 'right'
      }}
    />
  )

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <div className="home-page">
            <div class="welcome-section">
              <div class="container">
                <h1 className="welcome-section-header">
                  Welcome{name ? `, ${name}` : ''}!
                </h1>
                <p>Thank you for choosing Muslim.Estate.</p>
                <a
                  class="btn btn-lg btn-success mt-2 primary-action-btn"
                  href="/quiz"
                >
                  Create Will
                </a>
              </div>
            </div>
            <section className="exclusive-section pt-5 pb-2">
              <div className="container">
                <p>
                  <h2>Comprehensive Estate Planning</h2>
                  <span>
                    Schedule a consultation with us to discuss your needs
                  </span>
                  <br />
                </p>
                <a
                  class="btn btn-primary btn-lg"
                  href="https://calendly.com/yali/muslimestate"
                >
                  Schedule Now
                </a>
              </div>
            </section>
            <section class="services-section pt-5 pb-2">
              <div class="container">
                <h2>Other Services</h2>
                <span>Discover more ways we can assist you</span>
                <br />
                <div class="row mt-3">
                  <div class="col-md-4 mb-4">
                    <div class="service-card">
                      <h3>
                        Islamic Advance Directive <PaidIcon />
                      </h3>
                      <p>
                        Name healthcare agents and sharia compliant end of life
                        care preferances
                      </p>
                      <a
                        class="btn btn-lg btn-primary services-card-button disabled"
                        href="#"
                      >
                        Coming Soon
                      </a>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4">
                    <div class="service-card">
                      <h3>
                        Islamic Revocable Trust
                        <PaidIcon />
                      </h3>
                      <p>
                        Bypass the probate court system entirely by creating a
                        trust
                      </p>
                      <a
                        class="btn btn-lg btn-primary services-card-button disabled"
                        href="#"
                      >
                        Coming Soon
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="donate-section pt-4 pb-5">
              <div class="container">
                <h2>Support Our Project</h2>
                <span>Contributions to support our project are welcome</span>
                <br />
                <a href="/contribute" class="mt-3 btn btn-primary btn-lg">
                  Donate Now
                </a>
              </div>
            </section>
          </div>
        ) : (
          <Spinner color="primary" />
        )
      }
    </AuthUserContext.Consumer>
  )
}

const HomePage = () => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <div className="mt-4">
          <Home authUser={authUser} />
        </div>
      ) : (
        ''
      )
    }
  </AuthUserContext.Consumer>
)

const condition = (authUser) => !!authUser

export default compose(withAuthorization(condition))(HomePage)
