import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ReactGA from 'react-ga4'

import Navigation from '../Navigation'
import Footer from '../Footer'
import LandingPage from '../Landing'
import SignUpPage from '../SignUp'
import SignInPage from '../SignIn'
import PasswordForgetPage from '../PasswordForget'
import HomePage from '../Home'
import QuizPage from '../Quiz'
import AccountPage from '../Account'
import FaqsPage from '../Faq'
import TermsPage from '../Terms'
import Contribute from '../Contribute'
import Contact from '../Contact'

import * as ROUTES from '../../constants/routes'
import { withAuthentication } from '../Session'

if (window.location.hostname === 'app.muslim.estate') {
  console.log('Google Analytics initialized.')
  ReactGA.initialize('G-SGSHQLWNFZ')
}

const App = () => (
  <Router>
    <div>
      <Navigation />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />

      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.QUIZ} component={QuizPage} />

      <Route path={ROUTES.FAQ} component={FaqsPage} />
      <Route exact path={ROUTES.TERMS} component={TermsPage} />
      <Route path={ROUTES.CONTRIBUTE} component={Contribute} />

      <Route path={ROUTES.CONTACT} component={Contact} />

      <Footer />
    </div>
  </Router>
)

export default withAuthentication(App)
