exports.validateForm = function (state) {
  switch (state.formNumber) {
    case 1:
      if (!state.name || state.name.length === 0 || !state.name.trim()) {
        return 'Provide full name.'
      }
      if (!state.gender || state.name.length === 0 || !state.gender.trim()) {
        return 'Provide gender.'
      }
      if (
        !state.birthDayString ||
        state.birthDayString.length === 0 ||
        !state.birthDayString.trim()
      ) {
        return 'Provide birthday.'
      }
      return ''
    case 2:
      if (
        !state.address ||
        state.address.length === 0 ||
        !state.address.trim()
      ) {
        return 'Provide address.'
      }
      if (!state.city || state.city.length === 0 || !state.city.trim()) {
        return 'Provide city.'
      }
      if (!state.state || state.state.length === 0 || !state.state.trim()) {
        return 'Provide state.'
      }
      if (
        !state.postalCode ||
        state.address.length === 0 ||
        !state.postalCode.trim() ||
        !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(state.postalCode)
      ) {
        return 'Provide a valid postal code.'
      }
      return ''
    case 3:
      if (
        !state.maritalStatus ||
        state.maritalStatus.length === 0 ||
        !state.maritalStatus.trim()
      ) {
        return 'Provide marital status.'
      }
      if (
        !state.hasChildren ||
        state.hasChildren.length === 0 ||
        !state.hasChildren.trim()
      ) {
        return 'Provide living children/s status.'
      }
      if (
        !state.hasFather ||
        state.hasFather.length === 0 ||
        !state.hasFather.trim()
      ) {
        return 'Provide living father status.'
      }
      if (
        !state.hasMother ||
        state.hasMother.length === 0 ||
        !state.hasMother.trim()
      ) {
        return 'Provide living mother status.'
      }
      if (
        !state.hasSiblings ||
        state.hasSiblings.length === 0 ||
        !state.hasSiblings.trim()
      ) {
        return 'Provide sibling/s status.'
      }
      return ''
    case 4:
      if (
        state.maritalStatus === 'Married' &&
        ((!state.marriageDateString && state.marriageDateString.length === 0) ||
          !state.marriageDateString.trim())
      ) {
        return 'Provide marriage date.'
      }
      if (
        state.maritalStatus === 'Married' &&
        ((!state.spouseName && state.spouseName.length === 0) ||
          !state.spouseName.trim())
      ) {
        return 'Provide spouse name.'
      }
      if (
        state.hasChildren === '1' &&
        ((!state.numSons && state.numSons.length === 0) ||
          !state.numSons.trim())
      ) {
        return 'Provide sons count.'
      }
      if (
        state.hasChildren === '1' &&
        ((!state.numDaughters && state.numDaughters.length === 0) ||
          !state.numDaughters.trim())
      ) {
        return 'Provide daughters count.'
      }
      if (
        state.hasChildren === '1' &&
        state.numSons === '0' &&
        state.numDaughters === '0'
      ) {
        return 'Count of sons and daughters cannot be zero if you have children. Please provide correct count or change status to no children on previous page.'
      }
      if (
        state.hasFather === '1' &&
        ((!state.fatherName && state.fatherName.length === 0) ||
          !state.fatherName.trim())
      ) {
        return 'Provide father name.'
      }
      if (
        state.hasMother === '1' &&
        ((!state.motherName && state.motherName.length === 0) ||
          !state.motherName.trim())
      ) {
        return 'Provide mother name.'
      }
      if (
        state.hasSiblings === '1' &&
        ((!state.numBrothers && state.numBrothers.length === 0) ||
          !state.numBrothers.trim())
      ) {
        return 'Provide living brothers count.'
      }
      if (
        state.hasSiblings === '1' &&
        ((!state.numSisters && state.numSisters.length === 0) ||
          !state.numSisters.trim())
      ) {
        return 'Provide living sisters count.'
      }

      if (
        state.hasFather === '0' &&
        ((!state.hasPaternalGrandfather &&
          state.hasPaternalGrandfather.length === 0) ||
          !state.hasPaternalGrandfather.trim())
      ) {
        return "Provide paternal grandfather's status."
      }
      return ''
    case 5:
      if (
        state.hasChildren == '1' &&
        state.numSons > 0 &&
        state.sonNames.length < state.numSons
      ) {
        return 'Provide all son/s information.'
      }
      if (
        state.hasChildren == '1' &&
        state.numDaughters > 0 &&
        state.daughterNames.length < state.numDaughters
      ) {
        return 'Provide all daughter/s information.'
      }
      return ''
    case 6:
      if (
        state.hasFather === '0' &&
        state.hasPaternalGrandfather == '1' &&
        ((!state.grandFatherName && state.grandFatherName.length === 0) ||
          !state.grandFatherName.trim())
      ) {
        return 'Provide paternal grandfather name.'
      }

      if (
        state.numSons == '0' &&
        state.hasFather === '0' &&
        (!state.hasGrandchildren ||
          state.hasGrandchildren.length === 0 ||
          !state.hasGrandchildren.trim())
      ) {
        return 'Provide grandchildren/s status.'
      }
      if (state.hasFather == '0' && state.numSons == '0') {
        if (
          !state.hasFatherHalfSiblings ||
          state.hasFatherHalfSiblings.length === 0 ||
          !state.hasFatherHalfSiblings.trim()
        ) {
          return 'Provide father half siblings information.'
        }
        if (
          !state.hasNephews ||
          state.hasNephews.length === 0 ||
          !state.hasNephews.trim()
        ) {
          return 'Provide nephews information.'
        }
        if (
          !state.hasUncles ||
          state.hasUncles.length === 0 ||
          !state.hasUncles.trim()
        ) {
          return 'Provide uncles information.'
        }
      }
      return ''
    case 7:
      if (
        state.hasGrandchildren === '1' &&
        ((!state.numGrandsons && state.numGrandsons.length === 0) ||
          !state.numGrandsons.trim())
      ) {
        return 'Provide grand sons count.'
      }
      if (
        state.hasGrandchildren === '1' &&
        ((!state.numGranddaughters && state.numGranddaughters.length === 0) ||
          !state.numGranddaughters.trim())
      ) {
        return 'Provide grand daughters count.'
      }

      if (
        state.hasFatherHalfSiblings === '1' &&
        ((!state.numFatherHalfBrothers &&
          state.numFatherHalfBrothers.length === 0) ||
          !state.numFatherHalfBrothers.trim())
      ) {
        return 'Provide father half brothers count.'
      }
      if (
        state.hasFatherHalfSiblings === '1' &&
        ((!state.numFatherHalfSisters &&
          state.numFatherHalfSisters.length === 0) ||
          !state.numFatherHalfSisters.trim())
      ) {
        return 'Provide father half sisters count.'
      }
      if (
        state.hasMotherHalfSiblings === '1' &&
        ((!state.numMotherHalfSiblings &&
          state.numMotherHalfSiblings.length === 0) ||
          !state.numMotherHalfSiblings.trim())
      ) {
        return 'Provide mother half siblings count.'
      }
      if (
        state.hasNephews === '1' &&
        ((!state.numNephews && state.numNephews.length === 0) ||
          !state.numNephews.trim())
      ) {
        return 'Provide nephews count.'
      }
      if (
        state.hasUncles === '1' &&
        ((!state.numUncles && state.numUncles.length === 0) ||
          !state.numUncles.trim())
      ) {
        return 'Provide uncles count.'
      }
      return ''
    case 8:
      if (
        state.numBrothers > 0 &&
        state.brotherNames.length < state.numBrothers
      ) {
        return 'Provide all brother names.'
      }
      if (state.numSisters > 0 && state.sisterNames.length < state.numSisters) {
        return 'Provide all sister names.'
      }
      if (
        state.numGrandsons > 0 &&
        state.grandsonNames.length < state.numGrandsons
      ) {
        return 'Provide all grandson names.'
      }
      if (
        state.numGranddaughters > 0 &&
        state.granddaughterNames.length < state.numGranddaughters
      ) {
        return 'Provide all granddaughters names.'
      }
      if (
        state.numGrandsons > 0 &&
        state.grandsonNames.length < state.numGrandsons
      ) {
        return 'Provide all grandson names.'
      }
      if (
        state.numGranddaughters > 0 &&
        state.granddaughterNames.length < state.numGranddaughters
      ) {
        return 'Provide all granddaughters names.'
      }
      if (
        state.numFatherHalfBrothers > 0 &&
        state.fatherHalfBrotherNames.length < state.numFatherHalfBrothers
      ) {
        return 'Provide all father HalfBrother Names names.'
      }
      if (
        state.numFatherHalfSisters > 0 &&
        state.fatherHalfSisterNames.length < state.numFatherHalfSisters
      ) {
        return 'Provide all Father Half Sisters names.'
      }
      if (
        state.numMotherHalfSiblings > 0 &&
        state.motherHalfSiblingNames.length < state.numMotherHalfSiblings
      ) {
        return 'Provide all mother half siblings names.'
      }
      if (state.numNephews > 0 && state.nephewNames.length < state.numNephews) {
        return 'Provide all nephew names.'
      }
      if (state.hasUncles > 0 && state.uncleNames.length < state.numUncles) {
        return 'Provide all uncle names.'
      }
      return ''
    case 9:
      if (
        !state.assetClass ||
        state.assetClass.length === 0 ||
        !state.assetClass.trim()
      ) {
        return 'Provide approximate total value of everything you own.'
      }
      if (
        !state.profession ||
        state.profession.length === 0 ||
        !state.profession.trim()
      ) {
        return 'Select your profession.'
      }
      if (!state.assets || state.assets.length === 0) {
        return 'Select assets.'
      }
      if (
        !state.hasDebt ||
        state.hasDebt.length === 0 ||
        !state.hasDebt.trim()
      ) {
        return 'Provide debt status.'
      }
      if (
        state.hasDebt == '1' &&
        (!state.numDebts || state.numDebts.length === 0 || state.numDebts < 1)
      ) {
        return 'Provide number of debts.'
      }

      return ''
    case 10:
      if (state.hasDebt === '1' && state.debtNames.length < state.numDebts) {
        return 'Add all debt details.'
      }
      return ''
    case 11:
      if (
        (!state.personalRep1 ||
          state.personalRep1.length === 0 ||
          !state.personalRep1.trim()) &&
        state.maritalStatus !== 'Married'
      ) {
        return 'Provide first personal representative full name.'
      }
      if (
        (!state.personalRep1Relationship ||
          state.personalRep1Relationship.length === 0 ||
          !state.personalRep1Relationship.trim()) &&
        state.maritalStatus !== 'Married'
      ) {
        return 'Provide your relationship with first personal representative.'
      }
      if (
        state.hasChildren == '1' &&
        (!state.guardian1 ||
          state.guardian1.length === 0 ||
          !state.guardian1.trim()) &&
        state.maritalStatus !== 'Married'
      ) {
        return 'Provide first guardian full name.'
      }
      if (
        state.hasChildren == '1' &&
        (!state.guardian1Relationship ||
          state.guardian1Relationship.length === 0 ||
          !state.guardian1Relationship.trim()) &&
        state.maritalStatus !== 'Married'
      ) {
        return 'Provide your relationship with first guardian.'
      }
      if (
        !state.personalRep2 ||
        state.personalRep2.length === 0 ||
        !state.personalRep2.trim()
      ) {
        return 'Provide second personal representative full name.'
      }
      if (
        !state.personalRep2Relationship ||
        state.personalRep2Relationship.length === 0 ||
        !state.personalRep2Relationship.trim()
      ) {
        return 'Provide your relationship with second personal representative.'
      }
      if (
        state.hasChildren == '1' &&
        (!state.guardian2 ||
          state.guardian2.length === 0 ||
          !state.guardian2.trim())
      ) {
        return 'Provide second guardian full name.'
      }
      if (
        state.hasChildren == '1' &&
        (!state.guardian2Relationship ||
          state.guardian2Relationship.length === 0 ||
          !state.guardian2Relationship.trim())
      ) {
        return 'Provide your relationship with second guardian.'
      }
      if (
        !state.hasArbitration ||
        state.hasArbitration.length === 0 ||
        !state.hasArbitration.trim()
      ) {
        return 'Select appropriate option for arbitrator.'
      }
      if (
        state.hasArbitration == '1' &&
        (!state.arbitratorName ||
          state.arbitratorName.length === 0 ||
          !state.arbitratorName.trim())
      ) {
        return 'Provide arbitrator full name.'
      }
      return ''
    case 12:
      if (
        !state.hasDonations ||
        state.hasDonations.length === 0 ||
        !state.hasDonations.trim()
      ) {
        return 'Select appropriate option for gifts.'
      }
      if (
        state.hasDonations == '1' &&
        (!state.numDonations || state.numDonations <= 0)
      ) {
        return 'Provide valid number of gifts you want to include.'
      }
      if (
        state.hasDonations == '1' &&
        (!state.giftConfirmation || state.giftConfirmation == false)
      ) {
        return 'Confirm that your dollar amount donations do not exceed 1/3rd of your estate.'
      }
      return ''
    case 13:
      if (
        state.hasDonations == '1' &&
        state.donationNames.length < state.numDonations
      ) {
        return 'Provide all donation details.'
      }
      return ''
    default:
      return ''
  }
}
