import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { compose } from 'recompose'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'
import { withRouter } from 'react-router-dom'
import { Container } from 'reactstrap'
import Swal from 'sweetalert2'
import * as ROUTES from '../../constants/routes'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

function Contribute() {
  const [clientSecret, setClientSecret] = useState('')
  const user = localStorage.getItem('authUser')
    ? JSON.parse(localStorage.getItem('authUser'))
    : {}

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    fetch(`${process.env.REACT_APP_BACKEND_URL}create-checkout-session`, {
      method: 'POST',
      body: JSON.stringify({
        customer_email: user.email
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((err) => console.error(err))
  }, [])

  // Check is success message to be shown
  useEffect(() => {
    if (window.location.search.includes('success=1')) {
      window.history.pushState({}, document.title, ROUTES.CONTRIBUTE)
      Swal.fire({
        type: 'success',
        title: '<p style="text-color:blue">Muslim.Estate</p>',
        html:
          '<p>Congratulations! Your will document, along with execution instructions, will be sent to below email address shortly</p><b> ' +
          user.email +
          '</b>' +
          '<ul class="no-bullets" style="margin: 1rem 0 0 0; padding: 0"><li><p><a style="text-decoration: underline;" target="_blank" href="https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&u=https%3A%2F%2Fmuslim.estate%2F&display=popup&ref=plugin&src=share_button">Share on Facebook</a></p></li>' +
          '<li><p><a style="text-decoration: underline;" target="_blank" href="https://api.whatsapp.com/send?text=Check%20out%20https%3A%2F%2Fmuslim.estate%2F%20to%20create%20a%20free%20Islamic%20will">Tell a friend on WhatsApp</a></p></li>' +
          '<li><p>Please take a few moments to <a style="text-decoration: underline;" target="_blank" href="https://www.surveymonkey.com/r/Y3F9DC5">give us your feedback</a>.</p></li>' +
          '<li><p>Review your will with an Islamic estate planning attorney. <a style="text-decoration: underline;" target="_blank" href="https://calendly.com/yali/muslimestate">Schedule A Call</a></p></li></ul>',
        width: '45rem',
        showConfirmButton: true,
        showCloseButton: true
      })
    }
  }, [])

  const handleComplete = () => {
    Swal.fire({
      title: 'Thank you for your contribution!',
      text: 'Your generosity goes a long way in supporting our mission. We appreciate your commitment to making this service available to others. Thank you for being a part of our community.',
      icon: 'success',
      confirmButtonText: 'OK'
    })
  }

  return (
    <Container className="mt-5 mb-5" style={{ minHeight: '90vh' }}>
      <h1 className="text-primary">
        <b>Support Muslim.Estate</b>
      </h1>
      <p className="text-lg">
        Thank you for using our free will creation service. We are committed to
        keeping this service accessible to everyone, and your support can make a
        significant impact. If you find value in what we offer, consider making
        a contribution to help us cover the costs of hosting, maintaining the
        database, and ensuring the continued availability of this service.
      </p>
      <p className="text-lg">
        Every contribution, no matter the amount, is greatly appreciated. Please
        enter the amount you'd like to contribute (minimum $5) and choose your
        preferred payment method below.
      </p>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret, onComplete: handleComplete }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </Container>
  )
}

const ContributePage = compose(withRouter)(Contribute)
export default ContributePage
