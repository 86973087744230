import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Col,
  Row
} from 'reactstrap'
import { SignInGoogle, SignInLink } from '../SignIn'

const SignUpPage = () => (
  <div>
    <React.Fragment>
      <div className="main d-flex w-100">
        <Container className="d-flex flex-column">
          <Row className="h-100">
            <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <SignUpForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  </div>
)

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null
}

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use'

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them.
`

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    event.preventDefault()
    const { email, passwordOne } = this.state
    let error = {}
    let passErrorMessage =
      'Passwords must contain at least six characters, including uppercase, lowercase letters and numbers.'
    if (passwordOne != '') {
      if (passwordOne.length < 6) {
        error.message = passErrorMessage
        this.setState({ error })
        return false
      }
      let re = /[0-9]/
      if (!re.test(passwordOne)) {
        error.message = passErrorMessage
        this.setState({ error })
        return false
      }
      re = /[a-z]/
      if (!re.test(passwordOne)) {
        error.message = passErrorMessage
        this.setState({ error })
        return false
      }
      re = /[A-Z]/
      if (!re.test(passwordOne)) {
        error.message = passErrorMessage
        this.setState({ error })
        return false
      }
    } else {
      error.message = passErrorMessage
      this.setState({ error })
      return false
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.HOME)
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  render() {
    const { email, passwordOne, passwordTwo, error } = this.state

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '' || email === ''

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="text-center mt-4">
              <h1 className="h2">Sign Up</h1>
              <SignInLink />
            </div>
            <div className="m-sm-4">
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Enter your email"
                    onChange={this.onChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    placeholder="Enter password"
                    name="passwordOne"
                    value={passwordOne}
                    onChange={this.onChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    placeholder="Enter password"
                    name="passwordTwo"
                    value={passwordTwo}
                    onChange={this.onChange}
                  />
                </FormGroup>
                <div className="text-center mt-3">
                  <Button
                    color="primary"
                    size="lg"
                    disabled={isInvalid}
                    type="submit"
                  >
                    Sign Up
                  </Button>
                </div>
              </Form>
              <div className="text-center mt-3">
                {error && <p className="text-danger">{error.message}</p>}
              </div>
              <hr />
              <div className="text-center mt-3">
                <SignInGoogle />
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

const SignUpLink = () => (
  <p>
    <big>
      Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </big>
  </p>
)

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase)

export default SignUpPage

export { SignUpForm, SignUpLink }
